(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name trek.filter:errors
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('errors', errors);

  function errors() {
    return function (input, match) {
      if (!angular.isArray(input)) {
        console.log('errors filter expects an array as input');
        return;
      }
      var tmp = [],
        bits = [];
      for (var i = 0; i < input.length; i++) {
        bits = input[i].code.split(':');

        if (bits.length == 3 && bits[1] == match) {
          tmp.push(input[i].message);
        }
      }
      return tmp;
    };
  }
}());
